<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
  }
</script>
<style>
@media (min-width: 1904px) {
  .container {
     max-width: 1185px !important;
  }
}
body {
  font-family: 'Montserrat', 'Noto Sans SC', sans-serif;
  font-weight: 500;
  user-select: none;

}

th {
  font-weight: 500 !important;
  white-space: nowrap;
}

td {
}

.v-application .ikRounded{
  border-radius: 16px !important;
  border: 1px solid #bebebe;
}

.v-navigation-drawer .v-list-item__title{
  color:rgb(204, 204, 204) !important;
}

.v-navigation-drawer .v-list-item--active .v-list-item__title{
  color: white !important;
  font-weight: bold !important;
}

.v-navigation-drawer .v-list-item--active .v-list-item__icon{
  color: white !important;
  font-weight: bold !important;
}

.hideMore{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
