import { IKDataEntity } from 'metaflow-js'

const entity = {
  name: {
    displayName: 'Name',
  },
  lang: {},
  desc: {
    displayName: 'description',
    formConfig: {
      textArea: true,
      maxLength: 100,
      required: false,
    },
  },
}
export default IKDataEntity.ModelFactory(entity, {})
