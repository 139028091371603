import Vue from 'vue'
import Vuex from 'vuex'
import GlobalSettings from './GlobalSettings'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    barColor: 'rgba(72, 120, 227, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
    drawer: null,
    currentDeviceId: -1,
    // url: 'localhost',
    url: '192.168.1.178',
    protocol: location.protocol === 'https:' ? 'https://' : 'http://',
    dir: '/',
    accessPassword: '',
    accessLevel: '',
    modifyClientInfoPsw: 'Jhd961213',
  },
  mutations: {
    SAVE_CURRENT_DEVICE (state, payload) {
      state.url = GlobalSettings.Base
      state.currentDeviceId = payload
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_PASSWORD (state, payload) {
      state.accessPassword = payload
    },
    SET_ACCESS_LEVEL (state, payload) {
      state.accessLevel = payload
    },
  },
  actions: {},
  getters: {
    rootUrl: state => {
      return state.protocol + state.url + state.dir
    },
    rootCSVUrl: state => {
      return state.protocol + state.url + state.dir + GlobalSettings.endPoint + GlobalSettings.csvApi
    },
  },
})
