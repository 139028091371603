import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import { loadPrinterGroup } from '@/model/print/printerCommonApi'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
  },
  name: {
    displayName: 'sectionName',
  },
  isSingleLinePrint: {
    type: IKDataEntity.Types.Boolean,
    displayName: 'isSingleLinePrint',
  },
  isSingleOrderPrint: {
    header: false,
    type: IKDataEntity.Types.Boolean,
    displayName: 'isSingleOrderPrint',
  },
  upsideDown: {
    header: false,
    type: IKDataEntity.Types.Boolean,
  },
  printerCount: {
    displayName: 'printerCount',
    formConfig: {
      inEdit: false,
      inNew: false,
    },
  },
  defaultRetryCount: {
    formConfig: {
      default: '0',
    },
  },
  totalDishesCount: {
    formConfig: {
      inEdit: false,
      inNew: false,
    },
  },
}
const config = {
  load: loadPrinterGroup,
  add: function (item) {
    return hillo.postWithUploadFile('Printer.php?op=addPrinterGroups', item, {
      showLoading: true,
    })
  },
  edit: function (item, showLoading = true) {
    return hillo.postWithUploadFile('Printer.php?op=updatePrinterGroups', item, {
      showLoading,
    })
  },
  remove: function (id) {
    return hillo.post('Printer.php?op=deletePrinterGroup', { id: id })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
